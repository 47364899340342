.home_social_icon {
  font-size: 1.5rem;
  color: var(--title-color);
}
.home_social {
  display: grid;
  grid-template-columns: max-content;
}
.home_title {
  font-size: var(--big-font-size);
  margin-bottom: var(--mb-0-25);
  color:#594c8d;
  font-weight: bold;
}
.home_hand {
  width: 40px;
  height: 35px;
  margin-left: 0.5rem;
}
.home_subtitle {
  position: relative;
  font-size: var(--h3-font-size);
  padding-left: 5.4rem;
  font-weight: var(--font-normal);
}
.home_subtitle::before {
  content: "";
  position: absolute;
  width: 70px;
  height: 1px;
  background-color: var(--title-color);
  left: 0;
  top: 1rem;
}
.home_description {
  max-width: initial;
  margin-bottom: var(--mb-2-25);
}
.home_img {
  background: url(../../assets/profile-pic.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  box-shadow: inset 0 0 0 9px rgba(22, 19, 19, 0.448);
  order: 0;
  justify-self: center;
  width: 200px;
  height: 200px;
  /* border-radius: 50%; */
  animation: profile__animate 8s ease-in-out infinite 1s;
}
.circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #695aa6;
}

.line {
  width: 3px;
  height: 120px;
  background: linear-gradient(to bottom, #695aa6, transparent);
}
@keyframes profile__animate {
  0% {
    border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
  }
  50% {
    border-radius: 30% 60% 70% 40%/50% 60% 30% 60%;
  }
  100% {
    border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
  }
}
.wheel {
  animation: scroll 2s ease infinite;
}
@keyframes scroll {
  0% {
    transform: translateY(0);
  }
  30% {
    transform: translateY(3.75rem);
  }
}
/* .home_social_icon {
  margin-left: 10px;
  -webkit-animation: spin 4s linear infinite;
  -moz-animation: spin 4s linear infinite;
  animation: spin 4s linear infinite;
} */
@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.home_scroll-name {
  color: var(--title-color);
  font-weight: var(--font-medium);
  margin-right: var(--mb-0-25);
  margin-left: 2px;
  text-decoration: underline;
}
.home_scroll-arrow {
  font-size: 1.25rem;
  color: var(--title-color);
}
.home_data {
  grid-column: 1/3;
}
@media (min-width: 500px) {
  .home_img {
    width: 300px;
    height: 300px;
  }
}
@media (min-width: 992px) {
  .home_container {
    row-gap: 7rem;
  }
  .home_content {
    grid-template-columns: 116px repeat(2, 1fr);
    padding-top: 5.5rem;
    column-gap: 2rem;
    align-items: center;
  }
  .home_social {
    display: grid;
    grid-template-columns: max-content;
    row-gap: 1rem;
  }

  .home_social_icon:hover {
    transform: rotate(360deg);
    transition: all 1s ease-in-out;
    color: var(--title-color-dark);
  }
  .home_description {
    max-width: 400px;
    margin-bottom: var(--mb-3);
  }
  .home_img {
    width: 300px;
    height: 300px;
    order: 1;
  }
  .home_scroll {
    margin-left: 9.25rem;
  }
  .home_data {
    grid-column: 2/3;
  }
  .violet-gradient {
    background: #4dba87;
    background: linear-gradient(-90deg, #4dba87 0%, rgba(60, 51, 80, 0) 100%);
    background: -webkit-linear-gradient(
      -90deg,
      #4dba87 0%,
      rgba(60, 51, 80, 0) 100%
    );
  }
  .btn {
    display: flex;
    justify-content: center;
    align-items: center;
    left: 60vw;
    top: 8vw;
    width: 6vw;
    font-size: 1.2vw;
    height: 6vw;
    background: #594c8d;
    border-radius: 42% 58% 70% 30% / 41% 41% 59% 59%;
    transition: 0.5s;
    box-shadow: inset 20px 19px 10px 20px rgba(190, 1, 254, 0.05),
      2px 3px 10px 1px rgb(255 114 114 / 28%),
      3px 3px 20px 0px rgb(255 200 200 / 23%),
      inset 20px -20px 15px 20px rgba(255, 255, 255, 0.05);
  }

  .btn:hover {
    border-radius: 50%;
  }

}
