.project-card {
  text-align: center;
  margin: 10px 0;
}
.project-card img {
  height: 200px;
  width: 90vw;
  text-align: center;
  object-fit: cover;
  border-radius: 1.5rem;
}
@media (min-width: 768px) {
  .project-container {
    display: grid;
    grid-template-columns: auto auto;
    gap: 50px;
  }
  /* .project-card img:hover {
    translate: 1.5rem;
    transition: all 1s ease-in-out;
  } */
  .content {
    position: relative;
    width: 90%;
    max-width: 500px;
    margin: auto;
    overflow: hidden;
  }
  .content .content-overlay {
    background: rgba(0, 0, 0, 0.7);
    position: absolute;
    height: 99%;
    width: 100%;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    border-radius: 1.5rem;
    opacity: 0;
    -webkit-transition: all 0.4s ease-in-out 0s;
    -moz-transition: all 0.4s ease-in-out 0s;
    transition: all 0.4s ease-in-out 0s;
  }
  .content:hover .content-overlay {
    opacity: 1;
  }
  .content-details {
    position: absolute;
    text-align: center;
    padding-left: 1em;
    padding-right: 1em;
    width: 100%;
    top: 50%;
    left: 50%;
    opacity: 0;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-transition: all 0.3s ease-in-out 0s;
    -moz-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
  }

  .content:hover .content-details {
    top: 50%;
    left: 50%;
    opacity: 1;
  }
  .content-image {
    width: 100%;
  }

  .content-details h3 {
    color: #fff;
    font-weight: 500;
    letter-spacing: 0.15em;
    margin-bottom: 0.5em;
    text-transform: uppercase;
  }
  .content-details p {
    color: #fff;
    font-size: 0.8em;
  }
  .fadeIn-bottom {
    top: 80%;
  }
}
