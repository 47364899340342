.skills_group {
  display: grid;
  grid-template-columns: auto auto;
  gap: 1rem;
  align-items: center;
  text-align: center;
}
.skils-wrapper {
  gap: 1rem;
}
.technology{
  width: 70px;
  height: 70px;
  /* background-color: rgba(0, 0, 0, 0.1); */
  /* border-radius: 50%; */
  /* padding: 10px; */
}
@media (min-width: 768px) {
  .skills_content {
    background-color: var(--container-color);
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 2rem 4rem;
  }
  .skills_title {
    font-size: var(--normal-font-size);
    font-weight: var(--font-medium);
    text-align: center;
    margin-bottom: var(--mb-1-5);
  }
  .skills_box {
    display: flex;
    justify-content: center;
    column-gap: 2.5rem;
  }
  .skills_group {
    display: flex;
    gap: 100px;
  }
}
