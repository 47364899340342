.certification-details{
    letter-spacing: 2px;
    border: 2px solid rgba(0, 0, 0, 0.4);
    border-radius: 10px;
    padding: 20px;
    align-self: center;
}
.certification-image{
    max-width: 90vw;
    align-self: center;
}
@media (min-width:768px) {
    .certification-image{
        max-width: 80%;
        align-self: center;
    } 
}