.header {
  width: 100%;
  z-index: var(--z-fixed);
  background-color: var(--container-color);
}
.nav {
  height: calc(var(--header-height) + 1.5rem);
}
.nav__logo,
.nav__toggle {
  color: var(--title-color);
  font-weight: var(--font-medium);
}
.nav-headding {
  margin-left: calc((-9% - 0px) / 1);
}
.navbar{
  z-index: 9999;
}

@media (min-width: 992px) {
  .navbar-wrapper {
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
  }
  .nav-headding {
    margin-left: calc((-10% - 0px) / 1);
  }
  #basic-navbar-nav {
    margin-left: calc((100% - 752px) / 1);
  }
  .nav-link {
    position: relative;
    text-decoration: none;
  }
  .navbar{
    z-index: 9999;
  }
  .nav-link::after {
    content: "";
    position: absolute;
    width: 70%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 20%;
    background-color: #434242;
    transform-origin: bottom right;
    transition: transform 0.7s ease-out;
  }

  .nav-link:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }
}
