.about_img {
  object-fit: cover;
  border-radius: 2rem;
  width: 70vw;
}
.about-img-content {
  margin: 15px 0;
  text-align: center;
}
.about_box {
  background-color: var(--container-color);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.75rem;
  text-align: center;
  padding: 1rem 1.25rem;
}
.about_icon {
  font-size: 1.5rem;
  color: var(--title-color);
  margin-bottom: var(--mb-0-5);
}
.about_title {
  font-size: var(--small-font-size);
  font-weight: var(--font-medium);
}
.about_subtitle {
    font-size: var(--small-font-size);
  }
  
.about_info {
  grid-template-columns: repeat(3, 1fr);
  gap: 0.5rem;
  margin-bottom: var(--mb-2);
}
@media (min-width: 768px) {
  .about_container {
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    gap: 4rem;
    margin-top: 20px;
  }
  /* .about-img-content:hover img {
    cursor: pointer;
    scale: 1.03;
    transition: all 1s ease-in-out;
  } */
  .about_info {
    grid-template-columns: repeat(3, 240px);
    gap: 0.5rem;
    margin-bottom: var(--mb-2);
  }
  .about_description {
    padding: 0 4rem 0 0;
    margin-bottom: var(--mb-2-5);
  }
  .about_subtitle {
    font-size: var(--tiny-font-size);
  }
  
}
