.contact_container {
  display: grid;
  grid-template-columns: repeat(1, max-content);
  justify-content: center;
  column-gap: 6rem;
}
.contact_title {
  text-align: center;
  font-size: var(--h3-font-size);
  font-weight: var(--font-medium);
  margin-bottom: var(--mb-1-5);
}
.contact_form {
  width: 360px;
}
.contact_info {
  margin-top: 3rem;
  display: grid;
  row-gap: 1rem;
  grid-template-columns: 300px;
}
.contact_card {
  background-color: var(--container-color);
  border: 2px solid rgba(0, 0, 0, 0.2);
  padding: 1rem;
  border-radius: 0.75rem;
}
.contact_card-icon {
  font-size: 2rem;
  color: var(--title-color);
  margin-bottom: var(--mb-0-25);
}
.contact_card-title,
.contact_card-data {
  font-size: var(--small-font-size);
}
.contact_card-title {
  font-weight: var(--font-medium);
}
.contact_card-data {
  display: block;
  margin-bottom: var(--mb-0-75);
}
.contact_button {
  color: var(--text-color);
  font-size: var(--small-font-size);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  column-gap: 0.25rem;
}
.contact_button-icon {
  font-size: 1rem;
  transition: 0.5s;
}
.contact_button:hover .contact_button-icon {
  transform: translate(0.5rem);
}
.contact_form-div {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.contact_form-input {
  background-color: var(--container-color);
  /* border: 2px solid rgba(0, 0, 0, 0.4); */
  padding: 1rem;
  border-radius: 1rem;
  resize: both;
}
.contact_form-input:focus {
  border: 2px solid rgba(0, 0, 0, 0.4);
  outline: none;
}
.contact_form-tag {
  margin: 5px 0;
}
@media (min-width: 768px) {
  .contact_container {
    grid-template-columns: repeat(2, max-content);
  }
}
